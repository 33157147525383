.career-page {
  padding-top: 90px;
}

.career-faqs .accordion-item {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  margin-bottom: 80px;
}

.career-faqs .accordion-button:not(.collapsed) {
  height: 295px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #4faeff;
  border-radius: 10px 10px 0px 0px !important;
  color: #fff;
}

.career-faqs .accordion-button .btnWhite {
  display: none !important;
}

.career-faqs .accordion-button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 187px;
  border-radius: 10px !important;
  color: #000;
  padding: 0 75px !important;
}

.career-faqs .accordion-button:not(.collapsed) .custom-margin {
  margin-bottom: 24px;
}

.career-faqs .accordion-button:not(.collapsed) .btnWhite {
  display: block !important;
}

.career-faqs .accordion-button:focus {
  border: none !important;
  box-shadow: none !important;
}

.career-faqs .accordion-button .icon {
  font-size: 24px;
}

.career-faqs .accordion-button::after {
  background: url("../../assets/close-arrow.svg") no-repeat center center / contain;
  position: absolute;
  right: 75px;
  top: auto;
  width: 30px;
  height: 30px;
  transform: none !important;
}

.career-faqs .accordion-button:not(.collapsed)::after {
  background: url("../../assets/open-arrow.svg") no-repeat center center / contain;
  transform: none !important;
  margin-bottom: 100px;
}

.career-faqs .accordion-body {
  padding: 65px 127px;
}

.career-faqs .accordion-body .btnPrimary {
  width: 213px;
  height: 47px;
}

@media (max-width: 767px) {
  .career-faqs .accordion-button {
    padding: 0 20px !important;
    height: 120px;
  }
  .career-faqs .accordion-button::after {
    right: 22px;
  }
  .career-faqs .accordion-button:not(.collapsed) {
    height: 220px;
    align-items: flex-start;
  }
  .career-faqs .accordion-body {
    padding: 20px;
  }
  .career-faqs .accordion-item {
    margin-bottom: 40px;
  }
  .career-page {
    padding-top: 16px;
  }
}
