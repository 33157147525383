.social-icons ul {
  display: flex;
}

.social-icons ul li {
  list-style: none;
}

.social-icons ul li a {
  width: 35px;
  height: 35px;
  background-color: #fff;
  margin: 0 10px;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.social-icons ul li a .icon {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #2f89fc;
  transition: 0.5s;
  z-index: 3;
}

.social-icons ul li a:hover {
  background-color: transparent;
  border: 1px solid #fff;
}

/* .social-icons ul li a:hover .icon {
  color: #fff;
  transform: rotateY(360deg);
}

.social-icons ul li a:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.5s;
  z-index: 2;
} */

/* .social-icons ul li a:hover:before {
  top: 0;
}

.social-icons ul li:nth-child(1) a:before {
  background: #2f89fc;
  color: #fff;
}

.social-icons ul li:nth-child(2) a:before {
  background: #2f89fc;
  color: #fff;
}

.social-icons ul li:nth-child(3) a:before {
  background: #2f89fc;
  color: #fff;
} */
