html {
  scroll-behavior: smooth;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(47, 137, 252, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #2f89fc;
  outline: 1px solid #2f89fc;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  text-decoration: none;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.fs-28{
  font-size: 28px;
}

.textPrimary {
  color: #2f89fc;
}

.textSecondary {
  color: #453232;
}

.text-grey {
  color: #747474;
}

.text-lightgrey {
  color: #e7e7e7;
}

.text-darkgrey {
  color: #9e9e9e;
}

.texUpper {
  text-transform: uppercase;
}

.bgPrimary {
  background-color: #2f89fc;
}

.bgSecondary {
  background-color: #535353;
}

.ff-rubik {
  font-family: "Rubik";
}

/* font size */

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-25 {
  font-size: 25px;
}

.fs-30 {
  font-size: 30px;
}
.fs-34 {
  font-size: 34px;
}

.fs-40 {
  font-size: 40px;
  line-height: 47px;
}

.fs-45 {
  font-size: 45px;
  line-height: 1;
}

.fs-60 {
  font-size: 60px;
  line-height: 72px;
}

.fs-100 {
  font-size: 100px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.horizontalLine {
  position: relative;
  margin-top: 4px;
}

.horizontalLine {
  content: "";
  background-color: #2f89fc;
  width: 191px;
  height: 16px;
  display: block;
}

/* custom spaces */

.mt-64 {
  margin-top: 64px !important;
}

.mb-64 {
  margin-bottom: 64px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.nowrap {
  white-space: nowrap;
}

.mb-38 {
  margin-bottom: 38px;
}

.mt-38 {
  margin-top: 38px;
}

/* Navbar start */

nav {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

nav .navbar-brand {
  display: inline-block;
  width: 110px;
  height: 55px;
}

nav .navbar-brand img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

nav li:nth-child(2) {
  margin-left: 56px;
  margin-right: 72px;
}

nav .nav-item .nav-link {
  color: #9e9e9e !important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
}

nav .nav-item .nav-link.active {
  color: #535353 !important;
}

nav .btnPrimary {
  width: 149px;
  height: 43px;
  /* background: #2F89FC; */
}

input,
input:focus,
textarea,
textarea:focus {
  outline: 0;
  border: 0;
}

nav .navbar-toggler {
  padding-top: 13px;
}

nav .toggleLine {
  display: block;
  background-color: #9e9e9e;
  height: 6px;
  border-radius: 10px;
}

nav .toggleLine.top {
  width: 25px;
  margin-bottom: 4px;
}

nav .toggleLine.bottom {
  width: 15px;
  margin-left: auto;
}

/* Navbar end */

.btnPrimary {
  box-shadow: 0px 22px 11px -18px rgba(0, 0, 0, 0.25);
  border-radius: 5px !important;
  height: 47px;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 14px !important;
  color: #fff !important;
  position: relative;
  border: none;
  background: none;
  cursor: pointer;
  background-color: #2f89fc;
  z-index: 0;
  overflow: hidden;
}
.btnOutline {
  border: 2px solid #ffffff;
  box-shadow: 0px 22px 11px -18px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 43px;
  width: 188px;
  font-weight: 700;
  font-size: 14px;
  color: #fff;
}

.btnWhite {
  box-shadow: 0px 22px 11px -18px rgba(0, 0, 0, 0.25);
  border-radius: 5px !important;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  color: #2f89fc !important;
  position: relative;
  border: none;
  background: none;
  cursor: pointer;
  background-color: #fff;
  z-index: 0;
  overflow: hidden;
}

.btnPrimary:focus {
  outline-color: transparent;
  box-shadow: #2f89fc 2px 2px 22px;
}

.btnWhite:focus {
  outline-color: transparent;
  box-shadow: #2f89fc 2px 2px 22px;
}

a:hover {
  color: #2f89fc !important;
}

/* .btnPrimary .btnText {
  color: white;
} */

/* header style */

header.bgPrimary {
  background-color: #4faeff;
  color: #fff;
}

header {
  /* height: calc(100vh - 75px); */
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 120px;
}

header h1 {
  font-size: 40px;
}

header p {
  margin-top: 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 31px;
}

header .searchWithBtn {
  position: relative;
  width: 360px;
  height: 53px;
  background: #ffffff;
  border-radius: 5px;
  font-family: "Rubik";
}

header .searchWithBtn input {
  outline: 0;
  width: 100%;
  height: 100%;
  padding-left: 54px;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  color: #535353;
  background: #f8f8f8;
  border-radius: 5px;
  padding-right: 113px;
}
header .searchWithBtn input::placeholder {
  color: #535353;
}

header .searchWithBtn svg {
  position: absolute;
  top: 17px;
  left: 18px;
  font-size: 20px;
  color: #9e9e9e;
}

header .searchWithBtn a,
header .searchWithBtn button {
  position: absolute !important;
  right: 16px;
  top: 9px;
  padding: 0 !important;
  font-size: 10px !important;
}

.first-content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 45px;
}
.first-img {
  height: 424px;
  z-index: 11;
}
.first-img img {
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.first-img .overlay {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  width: 100%;
  height: 100%;
}
header .count-number {
  position: absolute;
  right: 0;
  font-size: 80px;
  margin-top: 25px;
}
.tagline-content {
  width: 75%;
  height: 70%;
  background: #6bbbff;
  position: absolute;
  bottom: -120px;
  left: -50px;
  display: flex;
  justify-content: end;
  flex-direction: column;
  padding: 40px 50px;
  z-index: -1;
}

.index-1 {
  z-index: 1;
}

.tag-arrow {
  font-size: 45px;
  color: #d9d9d9;
  line-height: 20px;
  cursor: pointer;
}

.tag-arrow:active {
  position: relative;
  top: 1px;
}

.tag-arrow.active {
  color: #fff;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* header style end */

/* techExperience start */

.techExperience {
  margin-bottom: 120px;
}

.techExperience .row {
  margin-top: 40px;
}

.experienceCard {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  padding: 33px 29px;
  height: 250px;
  transition: all 0.4s ease-in-out;
}

.experienceCard:hover {
  background-color: #2f89fc;
  color: #fff;
  cursor: pointer;
}

.experienceCard:hover .textSecondary {
  color: #fff;
}

.viewcontent p {
  display: none;
}

.experienceCard:hover .viewcontent img {
  display: none;
}

.experienceCard:hover .viewcontent p {
  display: block;
}

/* techExperience end */

/* portFolio */

.portFolio {
  margin-bottom: 50px;
}

.portFolio .row {
  margin-top: 40px;
}

/* .portFolio .portfolioCard .portfolio-img {
  width: 100%;
  height: 350px;
  background-color: #2F89FC;
  border-radius: 5px;
}

.portFolio .portfolioCard .portfolio-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
  top: 16px;
  left: 16px;
} */
.portFolio .portfolio-img {
  width: 100%;
  height: 426px;
  position: relative;
}

.portFolio .portfolio-img::before {
  content: "";
  background: #d9d9d9;
  opacity: 0.33;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  top: 20px;
  left: -20px;
  position: absolute;
  z-index: -1;
}

.portFolio .portfolio-img img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  /* position: relative;
  top: 16px;
  left: 16px; */
  border-radius: 10px;
}

.count-number {
  font-weight: 700;
  font-size: 100px;
  color: #d9d9d9;
}
.portFolio .row {
  align-items: flex-end;
}
.project-heading {
  font-weight: 600;
  font-size: 60px;
  color: #535353;
}

.project-des {
  font-weight: 400;
  font-size: 20px;
  color: #747474;
}

.portFolio .portfolioCard .inner {
  padding: 36px 80px;
}

.portFolio .btnPrimary {
  width: 213px;
  height: 47px;
  text-decoration: none;
}

.engagement-bg {
  background: #2f89fc;
  border-radius: 50px 0px 0px 0px;
  padding: 87px 35px;
  width: 97%;
  height: 100%;
}
.engagement-padding {
  border-radius: 0px 50px 0px 0px;
  padding: 87px 35px;
  padding-right: 40px;
  background: whitesmoke;
  width: 97%;
  height: 100%;
}
.engagement-padding .btnPrimary {
  padding: 10px 33px;
}
.engage-para {
  min-height: 70px;
}
.engage-heading {
  min-height: 70px;
}
/* workWith */

.workWith {
  margin-bottom: 120px;
}

.workWith .row.justify-content-lg-end {
  margin-bottom: 40px;
}

.workWith .icon-img {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.workWith .icon-img img {
  width: 100%;
  height: 100%;
}

/* customerSays */

.customerSays {
  margin-bottom: 120px;
}

.customerSays .horizontalLine {
  margin-bottom: 40px;
}

.testimonialCard {
  background: #ffffff;
  border: 1px solid #ededed;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 33px 0;
}

.testimonialCard .qoute {
  position: absolute;
  top: -32px;
  left: 30px;
  width: 54px;
  height: 54px;
  background-color: #2f89fc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qoute img {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.slick-slide > div {
  padding: 0 5px;
}

.testimonialCard .testiMain {
  padding: 50px 20px 20px 30px;
}

.testimonialCard .testFooter {
  padding: 10px 20px 24px 30px;
}

.testFooter .user {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.user img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
}

/* yearsExperince */

.yearsExperince {
  margin-bottom: 126px;
}

/* contactUs */

.contactUs {
  padding-bottom: 64px;
}

.contactUs .inputWithIcon,
.inputWithIcon {
  position: relative;
  font-family: "Rubik";
}

.contactUs .inputWithIcon svg,
.inputWithIcon svg {
  position: absolute;
  top: 22px;
  left: 18px;
  color: #535353 !important;
}

.contactUs .inputWithIcon input,
.inputWithIcon input {
  width: 100%;
  height: 60px;
  background: #fafafa;
  border: 2px solid #ededed;
  border-radius: 10px;
  padding-left: 52px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.contactUs .inputWithIcon input::placeholder,
.contactUs textarea::placeholder,
.inputWithIcon input::placeholder,
.getAQuote textarea::placeholder {
  color: #535353;
}

.contactUs textarea,
.getAQuote textarea {
  width: 100%;
  background: #fafafa;
  border: 2px solid #ededed;
  border-radius: 10px;
  padding-top: 21px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.contactUs textarea,
.getAQuote textarea {
  padding-left: 16px;
}

.contactUs .btnPrimary {
  width: 213px;
  height: 47px;
  text-decoration: none;
}

.getAQuote #cubic {
  display: inline-flex;
}

.contactUs textarea:focus,
.contactUs input:focus,
.getAQuote textarea:focus,
.getAQuote input:focus {
  border: 2px solid #2f89fc;
}

/* footer */

footer {
  background: #535353;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 60px 0;
  padding-bottom: 30px;
}
footer hr {
  background: white;
  margin-top: 70px;
}

@media (max-width: 1399px) {
  .nowrap {
    white-space: pre-wrap !important;
  }

  header .searchWithBtn {
    width: 100%;
  }
}
@media (max-width: 992px) {
  .searchWithBtn button {
    right: 10px !important;
    top: 12px !important;
    width: 72px;
    display: flex;
    justify-content: center;
    height: 28px;
    align-items: center;
  }
  header h1 {
    font-size: 26px;
  }
  .ff-rubik.fs-20 {
    font-size: 14px;
  }
  header .searchWithBtn {
    width: 100%;
  }
  header .searchWithBtn svg {
    left: 10px;
    font-size: 18px;
  }
  header .searchWithBtn input {
    font-size: 12px;
    padding-left: 30px;
    padding-right: 86px;
  }
  header .searchWithBtn a {
    right: 10px;
    font-size: 8px !important;
    width: 65px;
  }
  .fs-24 {
    font-size: 20px;
  }
  .first-img {
    height: 280px;
  }
  .first-content {
    padding: 15px;
  }
  .first-content .fs-24 {
    font-size: 18px !important;
  }
  .first-content .fs-14 {
    font-size: 12px;
  }
  .tagline-content {
    left: -15px;
    padding: 15px 15px;
    height: 95%;
  }
  header .count-number {
    font-size: 60px;
    margin-top: 50px;
  }
  header {
    margin-bottom: 60px;
  }
  .fs-60 {
    font-size: 34px;
    line-height: 40px;
  }
  .fs-100 {
    font-size: 50px;
  }
  .fs-30 {
    font-size: 20px;
  }
  .techExperience {
    margin-bottom: 0px;
  }
  .count-number {
    font-size: 60px;
  }
  .project-heading {
    font-size: 48px;
  }
  .portFolio .portfolio-img::before {
    height: 88%;
    top: 58px;
  }
  .sm-reverse {
    flex-direction: column-reverse;
  }
  .engagement-bg,
  .engagement-padding {
    min-width: 100%;
    border-radius: 30px;
    padding: 40px 30px;
  }
  .workWith .icon-img {
    width: 65px;
    height: 65px;
  }
  .customerSays,
  .workWith {
    margin-bottom: 30px;
  }
  .yearsExperince {
    margin-bottom: 40px;
  }

  nav li:nth-child(2) {
    margin: 20px 0;
  }

  .navbar-collapse.show {
    position: fixed;
    background: #fff;
    width: 70vw;
    top: 0;
    left: 0;
    padding: 45px;
    height: 100vh;
    z-index: 1;
    transition: none !important;
    animation: none;
    -webkit-transition: none;
  }

  .navbar-collapse.collapsing {
    -webkit-transition: none;
    transition: none;
  }
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.animatedLine {
  transition: all 1s ease-in-out;
}

.whiteBox {
  width: 100%;
  height: 50px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  animation: fillIn 2s forwards;
}

@keyframes fillIn {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}

.slide-head {
  display: none;
}

.slide-head.active-show {
  display: block;
  animation: fade_in_show 1s;
}

@keyframes fade_in_show {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* admin auth style */
.Auth span,
.create-project span {
  font-size: 12px;
  padding: 3px;
  color: red;
  display: none;
}

.Auth input:invalid[focused="true"],
.create-project input:invalid[focused="true"] {
  border: 1px solid red;
}

.Auth input:invalid[focused="true"] ~ span,
.create-project input:invalid[focused="true"] ~ span {
  display: block;
}

.show-hide {
  position: absolute;
  top: 40px;
  right: 24px;
  cursor: pointer;
  font-weight: 500;
}

.custom-shape-divider-bottom-1673109761 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  z-index: -1;
}

.custom-shape-divider-bottom-1673109761 svg {
  position: relative;
  display: block;
  width: calc(115% + 1.3px);
  height: 239px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1673109761 .shape-fill {
  fill: #2f89fc;
}

/* admin auth style */

@media (max-width: 767px) {
  header {
    height: 100%;
    margin-top: 30px;
    margin-bottom: 105px;
  }
  .tagline-content {
    left: -8px;
  }
  .techExperience {
    margin-bottom: 50px;
  }
  .customerSays,
  .workWith {
    margin-bottom: 60px;
  }
  header h1 {
    font-size: 30px;
  }

  header p {
    font-size: 14px;
  }

  .horizontalLine {
    height: 7px;
  }

  .fs-60 {
    font-size: 22px;
    line-height: 30px;
  }

  .fs-24,
  .fs-30 {
    font-size: 14px;
  }

  .fs-40 {
    font-size: 16px;
  }

  .fs-100 {
    font-size: 60px;
  }

  .portFolio .portfolioCard .portfolio-img {
    height: auto;
  }

  .portFolio .portfolioCard .inner {
    padding: 20px 15px;
  }

  .workWith .icon-img {
    width: 72px;
    height: 72px;
  }

  .portFolio .row {
    margin-top: -20px;
  }

  .contactUs .mb-38 {
    margin-bottom: 18px;
  }

  .contactUs textarea {
    padding-left: 20px;
  }

  footer .fs-18 {
    font-size: 15px;
  }

  .count-number {
    font-size: 50px;
  }
  .project-heading {
    font-size: 40px;
  }
  .project-des {
    font-size: 16px;
  }
  .portFolio .portfolio-img img {
    object-fit: cover;
  }
  .portFolio .portfolio-img {
    height: 182px;
  }
  .portFolio .portfolio-img::before {
    left: -8px;
    top: 31px;
  }
  .portFolio {
    margin-bottom: 50px;
    margin-top: 65px;
  }
  .engagements-model .team-icon {
    text-align: center;
  }
  .fs-45 {
    font-size: 28px;
  }
  .fs-25 {
    font-size: 16px;
  }

  .fs-34 {
    font-size: 16px;
  }
  .experienceCard {
    padding: 13px 14px;
    height: 230px;
  }
  .viewcontent img {
    width: 45px !important;
    height: 45px !important;
  }
  .fs-20 {
    font-size: 14px;
  }
  .experienceCard:hover .viewcontent p {
    font-size: 12px !important;
  }
}

