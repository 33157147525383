.details-page .count-number{
  line-height: 80px;
}
.details-page .first-section{
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 100px 0;
}
.detail-img{
  width: 100%;
  height: 420px;
  border-radius: 20px;
  border: 4px solid #2F89FC;
  /* padding: 25px; */
}
.detail-img img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 20px;
}
.content-detail.right-line::before{
  position: absolute;
  content: "";
  right: 10px;
  background: #747474;
  width: 7px;
  height: 118px;
  bottom: 55px;
}
.content-detail.left-line::before{
  position: absolute;
  content: "";
  left: 10px;
  background: #747474;
  width: 7px;
  height: 118px;
  bottom: 55px;
}
.content-detail .fs-20.fw-500.text-grey{
  width: 80% !important;
}
.padding-box{
  padding: 20px;
}
@media (max-width:992px){
  .details-page .first-section{
    padding: 40px 0;
  }
  .content-detail.left-line::before{
    bottom: 0;
  }
  .content-detail.right-line::before{
    bottom: 0;
  }
}
@media (max-width: 767px){
  .details-page .first-section{
    padding: 50px 0;
  }
  .detail-img{
    height: 270px;
    padding: 12px;
  }
  .content-detail.right-line::before{
    bottom: auto;
  }
  .content-detail.left-line::before{
    bottom: auto;
  }
}