.sidebar {
  padding: 30px 10px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
  height: 100vh;
  width: 200px;
  /* max-width: 20%; */
}

.sidebar .logo {
  width: 100px;
  height: 40px;
}

.sidebar .logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sidebar .nav-link {
  font-size: 15px;
  color: #212121;
  font-weight: 500;
  border-radius: 30px;
  margin: 10px 0;
}

.sidebar .nav-link.active {
  background-color: #2f89fc;
  border-radius: 30px;
  color: #fff;
}

.sidebar .nav-link.active:hover {
  color: #fff !important;
}

.btn-primary:hover {
  color: #fff !important;
}
