.client-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
}

.client-image .cross-icon {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.client-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
}

.company-name {
  font-size: 15px;
  font-weight: 500;
  color: #212121;
}

.client-name {
  font-size: 15px;
  font-weight: 700;
  color: #212121;
}

.input-area-user {
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  margin: 0 auto;
}

.input-area-user input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
