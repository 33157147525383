footer .foot-logo img {
  filter: brightness(0) invert(1);
}

footer .social ul a {
  color: #fff;
  font-size: 30px;
}

footer .social ul li:nth-child(2) {
  margin: 0 30px;
}

@media screen and (max-width: 767px) {
  footer {
    padding-left: 16px;
    padding-right: 16px;
  }
}
