.add-project {
  height: calc(100vh - 100px);
  overflow-y: auto;
  padding-right: 20px;
}

.add-project .card .btn-success {
  background-color: transparent;
  color: inherit;
  border: 0;
}

.add-project .card .dropdown-toggle::after {
  display: none;
}

.add-project .card .card-img {
  width: 100%;
  height: 150px;
  border-bottom: 1px solid #ccc;
}

.add-project .card .card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
