.about-section {
  padding-top: 90px;
}

.about-section .btnPrimary {
  width: 213px;
  height: 47px;
}

.about-img {
  width: 100%;
  height: 80%;
}

.about-img img {
  height: 100%;
  object-fit: cover;
}

.about-img::before {
  content: "";
  position: absolute;
  width: 90%;
  height: 90%;
  left: 150px;
  background: #6bbbff;
  z-index: -1;
  bottom: 124px;
}

.core-card {
  background: #ffffff;
  height: 100%;
  padding: 18px;
}

.core-section .row::before {
  background: #e5e5e5;
  content: "";
  position: absolute;
  top: -52px;
  right: 0;
  left: 0;
  width: 90%;
  margin: 0 auto;
  height: 330px;
  z-index: -1;
}

.model-card {
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  padding: 30px;
  height: 100%;
}

.model-card .model-icon {
  color: #2f89fc;
  font-size: 50px;
}

.model-card .model-icon svg {
  font-size: 50px;
}

.faq-section .accordion-item {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 40px;
}
.faq-section .accordion-button {
  background: #ffffff;
  border-radius: 10px 10px 0 0 !important;
  font-weight: 500;
  font-size: 24px;
  color: #535353;
  padding: 25px 40px;
}

.faq-section .accordion-button.collapsed {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px !important;
}
.faq-section .accordion-button:focus {
  border: none;
  box-shadow: none;
}
.faq-section .accordion-body {
  padding: 1rem 40px;
  font-weight: 400;
  font-size: 20px;
  color: #535353;
}

.faq-section .accordion-body ul li {
  list-style: disc;
  margin-left: 1rem;
  padding-left: 1rem;
}
/* .height-screen{
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
} */
@media (max-width: 992px) {
  .about-img::before {
    left: 60px;
    bottom: 40px;
    height: 100%;
  }
  .about-img {
    height: 350px;
  }
  .core-section .row::before {
    height: 100%;
    top: 0;
  }
}
@media (max-width: 767px) {
  .about-section {
    padding-top: 40px;
  }

  .about-img::before {
    content: none;
  }
  .about-img {
    height: 450px;
    margin-top: 50px;
  }
  .before-img img {
    height: 40px;
  }
  .faq-section .accordion-button {
    font-size: 18px;
    padding: 20px 22px;
  }
  .faq-section .accordion-body {
    padding: 1rem 22px;
    font-size: 14px;
  }
  .core-section .row::before {
    width: 100%;
    height: 100%;
    top: 0;
  }
}
