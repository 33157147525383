
/* chatBot css  */


.chatBot {
    bottom: 0;
    position: fixed;
    margin: 2em;
    right: 0;
    z-index: 9998;
  
  }
  
  .fab-ic {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    text-align: center;
    color: #f0f0f0;
    box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28);
    cursor: pointer;
    -webkit-transition: all .1s ease-out;
    transition: all .1s ease-out;
    position: relative;
    z-index: 9989;
    overflow: hidden;
    background: #2f89fc;
  }
  
  .fab-ic:hover {
    color: #2f89fc;
    background: #fff;
    transition: all .1s ease-out;
  }
  
  
  .chat {
    position: fixed;
    right: 2em;
    bottom: 2em;
    width: 100%;
    max-width: 360px;
    line-height: 0;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    opacity: 0;
    box-shadow: 1px 1px 100px 2px rgba(0, 0, 0, 0.22);
    border-radius: 20px;
    -webkit-transition: all .2s ease-out;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }
  
  
  .chat.is-visible {
    opacity: 1;
    -webkit-animation: zoomIn .2s cubic-bezier(.42, 0, .58, 1);
    animation: zoomIn .2s cubic-bezier(.42, 0, .58, 1);
  }
  
  .fab-ic img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  
  
  
  .chat .chat-box {
    width: 100%;
    height: 500px;
    padding: 15px;
    background: white;
    border-radius: 0px 0px 20px 20px;
    transition: height .2s ease-in;
  }
  
  .chat.is-mini .chat-box {
    width: 100%;
    height: 0;
    border-radius: 0px 0px 20px 20px;
    transition: height .2s ease-in;
  }
  
  
  .chat .chat-box body {
    overflow-y: hidden;
  }
  
  
  .fab-ic.is-fullscreen {
    width: 100%;
  }
  
  .chat .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding: 14px;
    font-size: 18px;
    background: #2f89fc;
  }
  
  .chat .header a {
    display: inherit;
    color: white;
  }
  .chat .header a:hover {
    color: white !important;
  }
  
  .min,
  .maxi {
    display: none !important;
  }


  
  .chat.is-mini .mini {
    display: none !important;
  }
  
  .chat.is-mini .maxi {
    display: inherit !important;
  }
  
  .chatBot.is-hide .fab-ic {
    display: none;
    margin: 0;
  }
  
  .chatBot.is-hide {
    margin: 0;
  }
  
  .chat.is-visible.is-mini .chat-box{
    padding: 0;
  }
  .chat.is-visible.is-mini .inner-chat, .chat.is-visible.is-mini .chat-input{
    display: none !important;
  }
  .chat.is-visible.is-mini .header{
    border-radius: 12px;
  }
  .chat-input {
	position: relative;
    transition: all .5s linear;
}

.chat-input input {
	background: #f8f8f8;
	border-radius: 8px;
	width: 100%;
	border: none;
	height: 48px;
    font-size: 14px;
	padding: 6px 20px;
}

.send-btns {
	position: absolute;
	right: 15px;
	top: 12px;
}

.inner-chat {
	height: calc(100% - 45px);
	overflow-y: auto;
    transition: all .3s linear;
}

.inner-chat::-webkit-scrollbar {
	width: 2px;
}
.sender {
	border: 1px solid rgba(80, 80, 80, 0.2);
	border-radius: 6px 6px 6px 0px;
	padding: 12px;
    line-height: 16px;
	max-width: 400px;
	display: inline-block;
	color: #505050;
	margin-bottom: 20px;
    line-height: 18px;
}
.receiver {
	border-radius: 6px 6px 0px 6px;
	padding: 12px;
    background: #2f89fc;
    line-height: 16px;
	max-width: 400px;
	display: inline-block;
	color: #fff;
	margin-bottom: 20px;
    line-height: 18px;
}

  
.chatBot .chat{
    z-index: -1;
    visibility: hidden;
  }

  .chatBot.chatBot.is-hide .chat{
    z-index: 9999;
    visibility: visible;
  }

  @media (max-width: 767px) {
      .chatBot.chatBot.is-hide {
        z-index: 9998;
      }
    .chat {
        position: fixed;
        right: 0;
        bottom: 0;
        height: 100%;
        left: 0;
        margin: 0 auto;
        border-radius: 0;
        width: 100%;
        max-width: 100%;
    }
    .chat .header{
        height: auto;
    }
  
    .chat .chat-box {
        height: calc(100vh - 50px);
        border-radius: 0;
    }
  
    .min,
    .max,
    .mini,
    .maxi {
        display: none !important;
    }
  
    .chatBot.is-hide {
        height: 100vh;
    }
  }