/* * * * * * * * *
CUBIC BUTTON
* * * * * * * * */
#cubic {
  color: white;
  position: relative;
  overflow: hidden;
  -webkit-perspective: 300px;
  -moz-perspective: 300px;
  -ms-perspective: 300px;
  -o-perspective: 300px;
  perspective: 280px;
  border: 0;
  background: 0;
  border-radius: 4px;
}

#cubic span {
  display: block;
  background: #2f89fc;
  padding: 10px 25px;
  top: 0;
  border-radius: 4px;
  transition: 0.4s all ease;
}

.btnPrimaryOutline {
  border: none;
  display: block;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  overflow: hidden;
  position: relative;
  color: #fff;
  font-weight: 700;
  font-size: 15px;
  background-color: #222;
  padding: 10px 20px;
  border-radius: 50px;
  background: transparent;
  border: 2px solid #fff;
}

#cubic:hover {
  color: white !important;
}

/* .btnPrimaryOutline span {
  position: relative;
  z-index: 1;
}

.btnPrimaryOutline:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 490%;
  width: 140%;
  background: #2f89fc;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
  transform: translateX(-98%) translateY(-25%) rotate(45deg);
}

.btnPrimaryOutline:hover:after {
  -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
  transform: translateX(-9%) translateY(-25%) rotate(45deg);
}

.btnPrimaryOutline:hover {
  border-color: transparent;
} */

/* button:not(:disabled, header .searchWithBtn button):active {
  position: relative;
  top: 1px;
} */

header .searchWithBtn button:active {
  top: 8px;
}

button:not(:disabled):hover {
  opacity: 0.8;
}

.btn-white:hover,
.btn-white {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #fff;
  background-image: linear-gradient(90deg, #fff, #fff);
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  color: #2f89fc;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}
.btn-white::before {
  background: #2f89fc;
  content: "";
  height: 155px;
  opacity: 0;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  width: 50px;
  transition: all 3000ms cubic-bezier(0.19, 1, 0.22, 1);
}
.btn-white::after {
  background: #2f89fc;
  content: "";
  height: 20rem;
  opacity: 0;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 3000ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 8rem;
}
.btn-white::before {
  left: -50%;
}

.btn-white::after {
  left: -100%;
}

.btn-white:hover,
.btn-white:active {
  transform: translateY(-3px);
  color: #2f89fc;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
}

.btn-white:hover::before {
  left: 120%;
  opacity: 0.5s;
}

.btn-white:hover::after {
  left: 200%;
  opacity: 0.6;
}
.btn-white span {
  z-index: 20;
}

.engagements-model .btnWhite,
.engagements-model button,
#cubic span {
  padding: 10px 25px !important;
}

.btn-check:active + .btn-success,
.btn-check:checked + .btn-success,
.btn-success.active,
.btn-success:active,
.show > .btn-success.dropdown-toggle {
  border-color: transparent;
  box-shadow: none !important;
}

.btn-check:focus + .btn-success,
.btn-success:focus {
  box-shadow: none !important;
}
