.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #535353 !important;
}

.navbar-nav .nav-link {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #9e9e9e !important;
  padding: 0 30px !important;
}

.navbarCustom .navbar-toggler-icon {
  /* background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e); */
  background: url("https://api.iconify.design/ri/menu-3-line.svg") no-repeat center center / contain;
  width: 25px;
  height: 25px;
}

.navbarCustom .navbar-toggler {
  border: 0 !important;
}

.navbarCustom .navbar-toggler:focus {
  box-shadow: none !important;
}

.mob-logo {
  display: inline-block;
  width: 100px;
  height: 55px;
}

.mob-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.offcanvas {
  max-width: 65%;
}

.getAQuote {
  width: 90% !important;
}

.getAQuote .offcanvas-body {
  padding: 1rem 60px;
}

@media screen and (max-width: 992px) {
  .navbar-nav .nav-link {
    padding: 12px 0 !important;
  }
}

@media screen and (max-width: 767px) {
  .getAQuote .offcanvas-body {
    padding: 1rem 1rem;
  }

  .getAQuote {
    width: 90% !important;
    max-width: 100% !important;
  }
}
