.input-area {
  position: relative;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #2f89fc;
  border-radius: 0.25rem;
  cursor: pointer;
}

.input-area input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.create-project .project-image {
  width: 100%;
  height: 200px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 20px;
}

.create-project .project-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 5px;
}

.create-project .project-image .cross-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.create-project.scroll {
  overflow-y: auto;
  height: calc(100vh - 100px);
}

.project-detail-img {
  width: 100%;
  height: 200px;
}
.project-detail-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
