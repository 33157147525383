.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  position: relative;
}

.header .user-link {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 8px;
  right: 0;
}

.header .btn-success {
  background-color: transparent;
  color: #000;
  border: 0;
}

.header .dropdown-toggle::after {
  display: none;
}

.header .btn-check:checked + .btn,
.header .btn.active,
.header .btn.show,
.header .btn:first-child:active,
:not(.btn-check) + .btn:active {
  background-color: transparent;
  color: #000;
}

.header .user-name {
  font-size: 16px;
  font-weight: 500;
  color: #5c5c5c;
  text-transform: capitalize;
}

.header .user-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid gray;
}

.header .user-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
}

.header .search {
  position: relative;
  width: 450px;
  margin: 0 auto;
}

.header .search input {
  padding-left: 50px;
}

.header .search span {
  position: absolute;
  top: 4px;
  left: 15px;
  color: #ccc;
  font-size: 24px;
}

.header .form-control:focus {
  box-shadow: none;
}

.header .dropdown-item.active,
.header .dropdown-item:active {
  background-color: #2f89fc;
}

.form-control:focus {
  border: 1px solid #ced4da !important;
}

@media screen and (max-width: 767px) {
  .header {
    justify-content: space-between;
    height: 80px;
  }
}
